'use client';

import {CustomButton} from '@/components/common';
import {useRouter} from '@/hooks';
import {useTranslations} from 'next-intl';

export default function Error() {
  const t = useTranslations();
  const router = useRouter();

  return (
    <div className="flex h-[100vh] flex-col items-center justify-center gap-[4.5rem]">
      <div className="flex flex-col items-center justify-center">
        <p className="font-main text-[18px] font-normal text-persianIndigo">
          {t('oops')}
        </p>
        <p className="font-main text-[18px] font-normal text-persianIndigo">
          {t('some_thing_went_wrong')}!
        </p>
      </div>
      <CustomButton
        btnStyles="w-[21rem]"
        value={t('return_home')}
        onClickHandling={() => router.replace('/')}
      />
    </div>
  );
}
